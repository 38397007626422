import React, { useState } from 'react'

const HeaderContext = React.createContext({
  height: 136, // pixels
  update: () => {},
})

function HeaderContextProvider(props) {
  const [height, setHeight] = useState(136)

  const update = attr => {
    setHeight(attr.height)
  }

  return (
    <HeaderContext.Provider value={{ height, update }}>
      {props.children}
    </HeaderContext.Provider>
  )
}

export default HeaderContext

export { HeaderContextProvider }
