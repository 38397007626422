// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/run/media/tony/0/gortek.v2/site/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-post-template-js": () => import("/run/media/tony/0/gortek.v2/site/src/components/postTemplate.js" /* webpackChunkName: "component---src-components-post-template-js" */),
  "component---src-pages-404-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-get-a-quote-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/get-a-quote.js" /* webpackChunkName: "component---src-pages-get-a-quote-js" */),
  "component---src-pages-index-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-boise-works-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/projects/boise-works.js" /* webpackChunkName: "component---src-pages-projects-boise-works-js" */),
  "component---src-pages-projects-deep-mile-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/projects/deep-mile.js" /* webpackChunkName: "component---src-pages-projects-deep-mile-js" */),
  "component---src-pages-projects-index-old-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/projects/index_old.js" /* webpackChunkName: "component---src-pages-projects-index-old-js" */),
  "component---src-pages-projects-index-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-pc-richards-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/projects/pc-richards.js" /* webpackChunkName: "component---src-pages-projects-pc-richards-js" */),
  "component---src-pages-projects-water-utility-app-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/projects/water-utility-app.js" /* webpackChunkName: "component---src-pages-projects-water-utility-app-js" */),
  "component---src-pages-services-api-development-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/services/api-development.js" /* webpackChunkName: "component---src-pages-services-api-development-js" */),
  "component---src-pages-services-app-startups-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/services/app-startups.js" /* webpackChunkName: "component---src-pages-services-app-startups-js" */),
  "component---src-pages-services-custom-software-development-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/services/custom-software-development.js" /* webpackChunkName: "component---src-pages-services-custom-software-development-js" */),
  "component---src-pages-services-discovery-project-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/services/discovery-project.js" /* webpackChunkName: "component---src-pages-services-discovery-project-js" */),
  "component---src-pages-services-index-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-startup-app-process-js": () => import("/run/media/tony/0/gortek.v2/site/src/pages/services/startup-app-process.js" /* webpackChunkName: "component---src-pages-services-startup-app-process-js" */)
}

